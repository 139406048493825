@use '../helpers/mixins';

.logos {
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
    justify-content: center;

    @include mixins.screen-size(mini, small) {
        margin-bottom: 3rem;
    }

    > img {
        margin: 0 2rem;
        max-width: 27%;

        @include mixins.screen-size(mini, small) {
            margin: 0 1rem;
            max-width: 29%;
        }
    }
}
