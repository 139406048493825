$dp-font-size-normal: 1em;
$dp-font-size-small: .75em;
$dp-cell-size-base: 2.25em;

$black: #2d2d2d;
$white: white;
$light: #efefef;
$dark: #3e3e3e;
$link: #2ca536;

$grey-dark: $black;
$grey-light: #646363;
$grey-lighter: #c9c9c9;

$dp-cell-selected-background-color: #2ca536;
$dp-cell-highlighted-background-color: $grey-light;

//-----------

@use 'sass:math';
@use 'sass:color';

//== foundational variables ==//
$black: hsl(0, 0%, 4%) !default;
$white: hsl(0, 0%, 100%) !default;
$light: hsl(0, 0%, 96%) !default; // white-ter
$dark: hsl(0, 0%, 21%) !default;  // grey-darker
$link: hsl(217, 71%, 53%) !default; // blue

$grey-dark: color.adjust($black, $lightness: 25%) !default;
$grey-light: color.adjust($light, $lightness: -25%) !default;
$grey-lighter: color.adjust($light, $lightness: -10%) !default;

//== datepicker variables ==//
$dp-background-color: $white !default;
$dp-border-color: $grey-lighter !default;
$dp-border-radius: 4px !default;
$dp-border-radius-small: 2px !default;
$dp-line-height-base: 1.5 !default;
$dp-font-size-normal: 1rem !default;
$dp-font-size-small: 0.75rem !default;
$dp-font-weight-semibold: 600 !default;
$dp-font-weight-bold: 700 !default;
$dp-dropdown-offset: 4px !default;
$dp-dropdown-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;
$dp-dropdown-z: 20 !default;

$dp-title-background-color: $light !default;

$dp-cell-size-base: 2.25rem !default;
$dp-cell-focus-background-color: color.adjust($light, $lightness: -5%) !default;
$dp-cell-prevnext-color: hsl(0, 0%, 48%) !default; // grey
$dp-cell-disabled-color: $grey-lighter !default;
$dp-cell-selected-background-color: $link !default;
$dp-cell-selected-color: #fff !default; // link(blue)-invert
$dp-cell-selected-font-weight: 600 !default;
$dp-cell-today-background-color: hsl(171, 100%, 41%) !default;  // turquoise (primary)
$dp-cell-today-color: #fff !default;  // turquoise-invert
$dp-cell-highlighted-background-color: $light !default;
$dp-range-start-end-background-color: $grey-light !default;
$dp-range-start-end-color: $dp-cell-selected-color !default;
$dp-range-background-color: $grey-lighter !default;
$dp-range-today-background-color: $dp-cell-today-background-color !default;
$dp-week-color: $grey-light !default;

$dp-footer-background-color: $light !default;

$dp-input-in-edit-border-color: color.adjust($link, $lightness: -5%) !default;
$dp-input-in-edit-focus-box-shadow-size: 0 0 0.25em 0.25em !default;

//== non-configurable variables ==//
$dp-cell-shrink-threshold: $dp-cell-size-base * 10;  // = 8 * 1.25
$dp-cell-shrinked-width: $dp-cell-size-base * math.div(7, 8);

//== mixins ==//

@mixin dp-header-button-common {
    border-color: transparent;
    font-weight: bold;
}

@mixin dp-footer-button-common {
    margin: calc(0.375rem - 1px) 0.375rem;
    border-radius: $dp-border-radius-small;
    width: 100%;
    font-size: $dp-font-size-small;
}

//== styles ==//
.datepicker {
    display: none;

    &.active {
        display: block;
    }
}

.datepicker-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $dp-dropdown-z;
    padding-top: $dp-dropdown-offset;

    &.datepicker-orient-top {
        padding-top: 0;
        padding-bottom: $dp-dropdown-offset;
    }
}

.datepicker-picker {
    display: inline-block;
    border-radius: $dp-border-radius;
    background-color: $dp-background-color;

    .datepicker-dropdown & {
        box-shadow: $dp-dropdown-shadow;
    }

    span {
        display: block;
        flex: 1;
        border: 0;
        border-radius: $dp-border-radius;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }
}

.datepicker-main {
    padding: 2px;
}

.datepicker-footer {
    box-shadow: inset 0 1px 1px rgba($black, 0.1);
    background-color: $dp-footer-background-color;
}

%flex-container {
    display: flex;
}

%flex-wrap {
    flex-wrap: wrap;
}

%flex-basis-day {
    flex-basis: percentage(math.div(1, 7));
}

%flex-basis-month-year {
    flex-basis: 25%;
}

%datepicker-cell-height {
    height: $dp-cell-size-base;
    line-height: $dp-cell-size-base;
}

.datepicker-title {
    box-shadow: inset 0 -1px 1px rgba($black, 0.1);
    background-color: $dp-title-background-color;
    padding: 0.375rem 0.75rem;
    text-align: center;
    font-weight: $dp-font-weight-bold;
}

.datepicker-controls {
    @extend %flex-container;

    .datepicker-header & {
        padding: 2px 2px 0;
    }

    @if mixin-exists(dp-button) {
        @include dp-button;
    } @else {
        .button {
            $button-color: $dark;
            $button-background-color: $white;

            $button-border-color: $grey-lighter;
            $button-border-width: 1px;

            $button-padding-vertical: calc(0.375em - #{$button-border-width});
            $button-padding-horizontal: 0.75em;

            $button-hover-color: $dark; // link-hover
            $button-hover-border-color: $grey-light;  // link-hover-border

            $button-focus-color: $dark; // link-focus
            $button-focus-border-color: $link;  // link-focus-border
            $button-focus-box-shadow-size: 0 0 0 0.125em;
            $button-focus-box-shadow-color: rgba($link, 0.25);

            $button-active-color: $dark;  // link-active
            $button-active-border-color: $grey-dark;  // link-active-border

            display: inline-flex;
            position: relative;
            align-items: center;
            justify-content: center;
            margin: 0;
            border: $button-border-width solid $button-border-color;
            border-radius: $dp-border-radius; // control-radius
            box-shadow: none;
            background-color: $button-background-color;
            cursor: pointer;
            padding: $button-padding-vertical $button-padding-horizontal;
            height: 2.25em; // control-height
            vertical-align: top;
            text-align: center;
            line-height: $dp-line-height-base;  // control-line-height
            white-space: nowrap;
            color: $button-color;
            font-size: $dp-font-size-normal;  // size-normal

            &:focus,
            &:active {
                outline: none;
            }

            &:hover {
                border-color: $button-hover-border-color;
                color: $button-hover-color;
            }

            &:focus {
                border-color: $button-focus-border-color;
                color: $button-focus-color;

                &:not(:active) {
                    box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color;
                }
            }

            &:active {
                border-color: $button-active-border-color;
                color: $button-active-color;
            }

            &[disabled] {
                cursor: not-allowed;
            }

            .datepicker-header & {
                @include dp-header-button-common;

                &:hover {
                    background-color: darken($white, 2.5%);
                }

                &:focus {
                    &:not(:active) {
                        box-shadow: 0 0 0 0.125em rgba($white, 0.25);
                    }
                }

                &:active {
                    background-color: darken($white, 5%);
                }

                &[disabled] {
                    box-shadow: none;
                }
            }

            .datepicker-footer & {
                @include dp-footer-button-common;
            }
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn,
    .next-btn {
        padding-right: 0.375rem;
        padding-left: 0.375rem;
        width: $dp-cell-size-base;

        &.disabled {
            visibility: hidden;
        }
    }
}

.datepicker-view {
    @extend %flex-container;

    .days-of-week {
        @extend %flex-container;
    }

    .dow {
        @extend %flex-basis-day;
        height: $dp-font-size-normal * $dp-line-height-base;
        line-height: $dp-font-size-normal * $dp-line-height-base;
        font-size: math.div(($dp-font-size-small + $dp-font-size-normal), 2);
        font-weight: $dp-font-weight-bold;
    }

    .week {
        @extend %datepicker-cell-height;
        width: $dp-cell-size-base;
        color: $dp-week-color;
        font-size: $dp-font-size-small;

        @media (max-width: $dp-cell-shrink-threshold) {
            width: $dp-cell-shrinked-width;
        }
    }
}

.datepicker-grid {
    @extend %flex-container;
    @extend %flex-wrap;

    width: $dp-cell-size-base * 7;

    @media (max-width: $dp-cell-shrink-threshold) {
        .calendar-weeks + .days & {
            width: $dp-cell-shrinked-width * 7;
        }
    }
}

.datepicker-cell {
    @extend %datepicker-cell-height;

    &:not(.disabled):hover {
        background-color: darken($dp-background-color, 2.5%);
        cursor: pointer;
    }

    &.focused:not(.selected) {
        background-color: $dp-cell-focus-background-color;
    }

    &.selected {
        &,
        &:hover {
            background-color: $dp-cell-selected-background-color;
            color: $dp-cell-selected-color;
            font-weight: $dp-cell-selected-font-weight;
        }
    }

    &.disabled {
        color: $dp-cell-disabled-color;
    }

    &.prev,
    &.next {
        &:not(.disabled) {
            color: $dp-cell-prevnext-color;
        }

        &.selected {
            color: darken($dp-cell-selected-color, 10%);
        }
    }

    &.highlighted:not(.selected):not(.range):not(.today) {
        border-radius: 0;
        background-color: $dp-cell-highlighted-background-color;

        &:not(.disabled):hover {
            background-color: darken($dp-cell-highlighted-background-color, 2.5%);
        }

        &.focused {
            background-color: $dp-cell-focus-background-color;
        }
    }

    &.today {
        &:not(.selected) {
            background-color: $dp-cell-today-background-color;

            &:not(.disabled) {
                color: $dp-cell-today-color;
            }
        }

        &.focused:not(.selected) {
            background-color: darken($dp-cell-today-background-color, 2.5%);
        }
    }

    &%range-start-end-common {
        background-color: $dp-range-start-end-background-color;
        color: $dp-range-start-end-color;
    }

    &%range-start-end-focused-common {
        background-color: darken($dp-range-start-end-background-color, 2.5%);
    }

    &.range-start {
        border-radius: $dp-border-radius 0 0 $dp-border-radius;

        &:not(.selected) {
            @extend %range-start-end-common;
        }

        &.focused:not(.selected) {
            @extend %range-start-end-focused-common;
        }
    }

    &.range-end {
        border-radius: 0 $dp-border-radius $dp-border-radius 0;

        &:not(.selected) {
            @extend %range-start-end-common;
        }

        &.focused:not(.selected) {
            @extend %range-start-end-focused-common;
        }
    }

    &.range {
        border-radius: 0;
        background-color: $dp-range-background-color;

        &:not(.disabled):not(.focused):not(.today):hover {
            background-color: darken($dp-range-background-color, 2.5%);
        }

        &.disabled {
            color: darken($dp-range-background-color, 10%);
        }

        &.focused {
            background-color: darken($dp-range-background-color, 5%);
        }

        @if $dp-range-today-background-color != $dp-cell-today-background-color {
            &.today {
                background-color: $dp-range-today-background-color;
            }
        }
    }

    .datepicker-view .days & {
        @extend %flex-basis-day;
    }

    .datepicker-view.datepicker-grid & {
        @extend %flex-basis-month-year;
        height: $dp-cell-size-base * 2;
        line-height: $dp-cell-size-base * 2;
    }
}

.datepicker-input.in-edit {
    border-color: $dp-input-in-edit-border-color;

    &:focus,
    &:active {
        box-shadow: $dp-input-in-edit-focus-box-shadow-size rgba($dp-input-in-edit-border-color, 0.2);
    }
}

//-----------

.datepicker-title {
    padding: .375em .75em;
}

.datepicker-cell {
    &.focused:not(.selected) {
        color: var(--color-white);
    }
}

.prev-btn,
.next-btn {
    padding-right: .375em;
    padding-left: .375em;
}
