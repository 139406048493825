.booking__summary {
    background-color: var(--color-gray-lighter);
    padding: 2.6rem 2.2rem 2.3rem;

    > .button {
        margin: 3rem 0 0;
    }
}

.booking__title {
    color: var(--color-black);
    font-weight: var(--font-weight-bold);
}

.booking__info {
    display: flex;
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    b {
        color: var(--color-black);
        font-weight: var(--font-weight-bold);
    }

    &::before {
        content: '';
        display: block;
        margin: .2rem 1.7rem 0 0;
    }

    &.-location {
        margin-bottom: 1.7rem;

        &::before {
            background-image: url("../../images/location.svg");
            background-size: 1.1rem 1.4rem;
            width: 1.1rem;
            height: 1.4rem;
        }
    }

    &.-date {
        &::before {
            background-image: url("../../images/calendar.svg");
            background-size: 1.2rem 1.4rem;
            width: 1.2rem;
            height: 1.4rem;
        }
    }

    &.-time {
        &::before {
            margin-right: 1.5rem;
            background-image: url("../../images/time.svg");
            background-size: 1.4rem 1.4rem;
            width: 1.4rem;
            height: 1.4rem;
        }
    }
}

.booking__message {
    max-width: 72rem;
    color: var(--color-gray);
    font-size: 1.8rem;
    line-height: 1.56;
}
