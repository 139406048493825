@use '../base/typography/title';

.steps {
    counter-reset: steps-counter;
    padding: 0;

    &__step {
        text-align: center;

        &:hover {
            .steps__icon::after {
                transform: scale(1.4) rotate(5deg);
                color: var(--color-primary);
            }

            .steps__icon img {
                transform: scale(1.2);
            }

            .steps__title {
                color: var(--color-primary);

                &::after {
                    max-width: 140px;
                }
            }
        }
    }

    &__icon {
        position: relative;

        display: inline-block;

        width: 162px;
        height: 160px;
        margin-block-end: 2rem;

        text-align: center;

        background: url(../../images/step-holder.svg) no-repeat;
        background-size: 100%;

        img {
            display: inline-block;
            margin-block-start: 36px;
            margin-inline-start: -5px;
            transition: all .2s ease-in-out;
        }

        &::after {
            content: counter(steps-counter);
            counter-increment: steps-counter;

            position: absolute;
            inset-block-start: 122px;
            inset-inline-start: 0;

            width: 100%;

            font-family: var(--font-secondary);
            font-size: 2rem;
            font-weight: var(--font-weight-bold);
            color: var(--color-gray);

            transition: all .2s ease-in-out;
        }

        &.-search img {
            margin-block-start: 40px;
        }

        &.-store img {
            margin-block-start: 40px;
            margin-inline-start: 2px;
        }
    }

    &__title {
        @extend .title--3;

        &::after {
            @extend %small-line-after;

            transition: max-width .2s ease-in-out;
        }
    }
}
