@use '../helpers/mixins';
@use '../base/typography/list';

ul.list--downloads {
    @extend %list;
    @extend %ul;

    li {
        font-size: 1.4rem;

        &:before {
            background-image: url('../../images/paper-sheet.svg');
            width: 11px;
            height: 14px;
            left: -24px;
            top: 4px;

            @include mixins.screen-size(mini, small) {
                left: -21px;
            }
        }

        a {
            font-size: 1.6rem;
        }
    }
}
