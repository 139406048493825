.downloads-list {
    column-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15em, 21em));
    margin-bottom: 2em;
    padding-left: 0;
    row-gap: 1.4rem;

    > li {
        display: block;

        > a {
            align-items: center;
            background-color: var(--color-gray-light2);
            color: var(--color-black);
            display: flex;
            justify-items: start;
            padding: 1rem 5rem 1rem 1rem;
            position: relative;
            text-decoration: none;

            &:hover, &:active, &:focus {
                text-decoration: underline;
            }

            &::before {
                background-image: url('../../images/contract.jpg');
                content: '';
                display: inline-block;
                height: 4.1rem;
                margin-right: .5em;
                width: 3.1rem;
            }

            &::after {
                background-image: url('../../images/download.svg');
                content: '';
                display: inline-block;
                height: 1.8rem;
                position: absolute;
                right: 2rem;
                top: 50%;
                transform: translateY(-50%);
                width: 1.5rem;
            }
        }
    }
}
