@use '../helpers/mixins';

/* ==========================================================================
   Variables
   ========================================================================== */
/* Colors */
/* Responsive true/false */
/* Default sizes */
/* Screen adaptive config */
/*
 *   This one needs some explanation:
 *
 *   It might look weird at first but it's actually pretty logical: to get a number without its unit, you need to divide
 *   it by 1 of the same unit. To get 42 from 42em, you need to divide 42em by 1em.
 *
 *   So we divide our number by the same number multiplied by 0 to which we then add 1.
 *   With our example, here is what happen: 42em / 42em * 0 + 1, so 42em / 0em + 1 so, 42em / 1em so 42.
 */
/**
 * You can use font-smoothing best when using light text on a dark background
 * This only works on Mac OS X
 *
 * More info:
 * - http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
 * - http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
/* MIXIN */
/* Make sure we have all parameters we gonna need */
/* Grid Container */
.grid__container, .grid--v-large__container, .grid__container--test {
  box-sizing: border-box;
  position: relative;
  max-width: 1400px;
  margin: 0 auto; }
  @media only screen and (min-width: 1081px) {
    .grid__container, .grid--v-large__container, .grid__container--test {
      padding-left: 50px;
      padding-right: 50px; } }
  @media only screen and (min-width: 821px) and (max-width: 1080px) {
    .grid__container, .grid--v-large__container, .grid__container--test {
      padding-left: 25px;
      padding-right: 25px; } }
  @media only screen and (min-width: 501px) and (max-width: 820px) {
    .grid__container, .grid--v-large__container, .grid__container--test {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 500px) {
    .grid__container, .grid--v-large__container, .grid__container--test {
      padding-left: 20px;
      padding-right: 20px; } }

@media only screen and (max-width: 500px) {
  .grid--v-large__container {
    margin: 0;
    padding: 0; } }
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .grid--v-large__container {
    margin: 0;
    padding: 0; } }
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-large__container {
    margin: 0;
    padding: 0; } }

.grid__container--test [class*="__col"] {
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border: 0 solid #ccc;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
.grid__container--test [class*="__col"] [class*="__col"] {
  margin-bottom: 0; }

/* Grid Row */
.grid__row {
  width: 100%; }
  .grid__row:before, .grid__row:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */ }
  .grid__row:after {
    clear: both; }

/* Grid Col */
[class*="__col--"],
[class*="__cols--"] {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  width: 100%; }
  [class*="__col--"].-omega,
  [class*="__cols--"].-omega {
    margin-right: 0 !important; }

@media only screen and (min-width: 1081px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-large__col--1,
  .grid--v-large__cols--1 {
    width: 3.75%;
    margin-right: 5%; }

  .grid--v-large__cols--1:nth-child(12n) {
    margin-right: 0; }
  .grid--v-large__cols--1:nth-child(12n +1) {
    clear: left; }

  .-v-large__offset--1 {
    margin-left: 8.75%; }

  .grid--v-large__col--2,
  .grid--v-large__cols--2 {
    width: 12.5%;
    margin-right: 5%; }

  .grid--v-large__cols--2:nth-child(6n) {
    margin-right: 0; }
  .grid--v-large__cols--2:nth-child(6n +1) {
    clear: left; }

  .-v-large__offset--2 {
    margin-left: 17.5%; }

  .grid--v-large__col--3,
  .grid--v-large__cols--3 {
    width: 21.25%;
    margin-right: 5%; }

  .grid--v-large__cols--3:nth-child(4n) {
    margin-right: 0; }
  .grid--v-large__cols--3:nth-child(4n +1) {
    clear: left; }

  .-v-large__offset--3 {
    margin-left: 26.25%; }

  .grid--v-large__col--4,
  .grid--v-large__cols--4 {
    width: 30%;
    margin-right: 5%;

      .grid__row.-homepage & {
          @include mixins.screen-size(mini, small, medium) {
              width: 38.75%;
              margin-right: 5%;
          }
      }
  }

  .grid--v-large__cols--4:nth-child(3n) {
    margin-right: 0; }
  .grid--v-large__cols--4:nth-child(3n +1) {
    clear: left; }

  .-v-large__offset--4 {
    margin-left: 35%; }

  .grid--v-large__col--5,
  .grid--v-large__cols--5 {
    width: 38.75%;
    margin-right: 5%; }

  .grid--v-large__cols--5:nth-child(2n) {
    margin-right: 0; }
  .grid--v-large__cols--5:nth-child(2n +1) {
    clear: left; }

  .-v-large__offset--5 {
    margin-left: 43.75%; }

  .grid--v-large__col--6,
  .grid--v-large__cols--6 {
    width: 47.5%;
    margin-right: 5%; }

  .grid--v-large__cols--6:nth-child(2n) {
    margin-right: 0; }
  .grid--v-large__cols--6:nth-child(2n +1) {
    clear: left; }

  .-v-large__offset--6 {
    margin-left: 52.5%;
  }

  .grid--v-large__col--7,
  .grid--v-large__cols--7 {
    width: 56.25%;
    margin-right: 5%;

      .grid__row.-homepage & {
          @include mixins.screen-size(mini, small, medium) {
              width: 47.5%;
              margin-right: 5%;
          }
      }
  }

  .grid--v-large__cols--7:nth-child(1n) {
    margin-right: 0; }
  .grid--v-large__cols--7:nth-child(1n +1) {
    clear: left; }

  .-v-large__offset--7 {
    margin-left: 61.25%; }

  .grid--v-large__col--8,
  .grid--v-large__cols--8 {
    width: 65%;
    margin-right: 5%; }

  .grid--v-large__cols--8:nth-child(1n) {
    margin-right: 0; }
  .grid--v-large__cols--8:nth-child(1n +1) {
    clear: left; }

  .-v-large__offset--8 {
    margin-left: 70%; }

  .grid--v-large__col--9,
  .grid--v-large__cols--9 {
    width: 73.75%;
    margin-right: 5%; }

  .grid--v-large__cols--9:nth-child(1n) {
    margin-right: 0; }
  .grid--v-large__cols--9:nth-child(1n +1) {
    clear: left; }

  .-v-large__offset--9 {
    margin-left: 78.75%; }

  .grid--v-large__col--10,
  .grid--v-large__cols--10 {
    width: 82.5%;
    margin-right: 5%; }

  .grid--v-large__cols--10:nth-child(1n) {
    margin-right: 0; }
  .grid--v-large__cols--10:nth-child(1n +1) {
    clear: left; }

  .-v-large__offset--10 {
    margin-left: 87.5%; }

  .grid--v-large__col--11,
  .grid--v-large__cols--11 {
    width: 91.25%;
    margin-right: 5%; }

  .grid--v-large__cols--11:nth-child(1n) {
    margin-right: 0; }
  .grid--v-large__cols--11:nth-child(1n +1) {
    clear: left; }

  .-v-large__offset--11 {
    margin-left: 96.25%; }

  /* Subgrids */
  /* Variables */
  .grid--v-large__col--4-8,
  .grid--v-large__cols--4-8 {
    width: 46.15385%;
    margin-right: 7.69231%; }

  .grid--v-large__cols--4-8:nth-child(2n) {
    margin-right: 0; }
  .grid--v-large__cols--4-8:nth-child(2n +1) {
    clear: left; }

  /* Omega class */
  .-v-large__omega {
    margin-right: 0 !important; } }
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-medium__col--1,
  .grid--v-medium__cols--1 {
    width: 5.51282%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--1:nth-child(12n) {
    margin-right: 0; }
  .grid--v-medium__cols--1:nth-child(12n +1) {
    clear: left; }

  .-v-medium__offset--1 {
    margin-left: 8.58974%; }

  .grid--v-medium__col--2,
  .grid--v-medium__cols--2 {
    width: 14.10256%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--2:nth-child(6n) {
    margin-right: 0; }
  .grid--v-medium__cols--2:nth-child(6n +1) {
    clear: left; }

  .-v-medium__offset--2 {
    margin-left: 17.17949%; }

  .grid--v-medium__col--3,
  .grid--v-medium__cols--3 {
    width: 22.69231%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--3:nth-child(4n) {
    margin-right: 0; }
  .grid--v-medium__cols--3:nth-child(4n +1) {
    clear: left; }

  .-v-medium__offset--3 {
    margin-left: 25.76923%; }

  .grid--v-medium__col--4,
  .grid--v-medium__cols--4 {
    width: 31.28205%;
    margin-right: 3.07692%;

      .grid__row.-homepage & {
          @include mixins.screen-size(mini, small, medium) {
              width: 39.87179%;
              margin-right: 3.07692%;
          }
      }
  }

  .grid--v-medium__cols--4:nth-child(3n) {
    margin-right: 0; }
  .grid--v-medium__cols--4:nth-child(3n +1) {
    clear: left; }

  .-v-medium__offset--4 {
    margin-left: 34.35897%; }

  .grid--v-medium__col--5,
  .grid--v-medium__cols--5 {
    width: 39.87179%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--5:nth-child(2n) {
    margin-right: 0; }
  .grid--v-medium__cols--5:nth-child(2n +1) {
    clear: left; }

  .-v-medium__offset--5 {
    margin-left: 42.94872%; }

  .grid--v-medium__col--6,
  .grid--v-medium__cols--6 {
    width: 48.46154%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--6:nth-child(2n) {
    margin-right: 0; }
  .grid--v-medium__cols--6:nth-child(2n +1) {
    clear: left; }

  .-v-medium__offset--6 {
    margin-left: 51.53846%; }

  .grid--v-medium__col--7,
  .grid--v-medium__cols--7 {
    width: 57.05128%;
    margin-right: 3.07692%;

      .grid__row.-homepage & {
          @include mixins.screen-size(mini, small, medium) {
              width: 48.46154%;
              margin-right: 3.07692%;
          }
      }
  }

  .grid--v-medium__cols--7:nth-child(1n) {
    margin-right: 0; }
  .grid--v-medium__cols--7:nth-child(1n +1) {
    clear: left; }

  .-v-medium__offset--7 {
    margin-left: 60.12821%; }

  .grid--v-medium__col--8,
  .grid--v-medium__cols--8 {
    width: 65.64103%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--8:nth-child(1n) {
    margin-right: 0; }
  .grid--v-medium__cols--8:nth-child(1n +1) {
    clear: left; }

  .-v-medium__offset--8 {
    margin-left: 68.71795%; }

  .grid--v-medium__col--9,
  .grid--v-medium__cols--9 {
    width: 74.23077%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--9:nth-child(1n) {
    margin-right: 0; }
  .grid--v-medium__cols--9:nth-child(1n +1) {
    clear: left; }

  .-v-medium__offset--9 {
    margin-left: 77.30769%; }

  .grid--v-medium__col--10,
  .grid--v-medium__cols--10 {
    width: 82.82051%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--10:nth-child(1n) {
    margin-right: 0; }
  .grid--v-medium__cols--10:nth-child(1n +1) {
    clear: left; }

  .-v-medium__offset--10 {
    margin-left: 85.89744%; }

  .grid--v-medium__col--11,
  .grid--v-medium__cols--11 {
    width: 91.41026%;
    margin-right: 3.07692%; }

  .grid--v-medium__cols--11:nth-child(1n) {
    margin-right: 0; }
  .grid--v-medium__cols--11:nth-child(1n +1) {
    clear: left; }

  .-v-medium__offset--11 {
    margin-left: 94.48718%; }

  /* Subgrids */
  /* Omega class */
  .-v-medium__omega {
    margin-right: 0 !important; } }
@media only screen and (min-width: 501px) and (max-width: 820px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-small__col--1,
  .grid--v-small__cols--1 {
    width: 14.42308%;
    margin-right: 2.69231%; }

  .grid--v-small__cols--1:nth-child(6n) {
    margin-right: 0; }
  .grid--v-small__cols--1:nth-child(6n +1) {
    clear: left; }

  .-v-small__offset--1 {
    margin-left: 17.11538%; }

  .grid--v-small__col--2,
  .grid--v-small__cols--2 {
    width: 31.53846%;
    margin-right: 2.69231%; }

  .grid--v-small__cols--2:nth-child(3n) {
    margin-right: 0; }
  .grid--v-small__cols--2:nth-child(3n +1) {
    clear: left; }

  .-v-small__offset--2 {
    margin-left: 34.23077%; }

  .grid--v-small__col--3,
  .grid--v-small__cols--3 {
    width: 48.65385%;
    margin-right: 2.69231%; }

  .grid--v-small__cols--3:nth-child(2n) {
    margin-right: 0; }
  .grid--v-small__cols--3:nth-child(2n +1) {
    clear: left; }

  .-v-small__offset--3 {
    margin-left: 51.34615%; }

  .grid--v-small__col--4,
  .grid--v-small__cols--4 {
    width: 65.76923%;
    margin-right: 2.69231%; }

  .grid--v-small__cols--4:nth-child(1n) {
    margin-right: 0; }
  .grid--v-small__cols--4:nth-child(1n +1) {
    clear: left; }

  .-v-small__offset--4 {
    margin-left: 68.46154%; }

  .grid--v-small__col--5,
  .grid--v-small__cols--5 {
    width: 82.88462%;
    margin-right: 2.69231%; }

  .grid--v-small__cols--5:nth-child(1n) {
    margin-right: 0; }
  .grid--v-small__cols--5:nth-child(1n +1) {
    clear: left; }

  .-v-small__offset--5 {
    margin-left: 85.57692%; }

  /* Subgrids */
  /* Variables */
  .grid--v-small__col--1-2,
  .grid--v-small__cols--1-2 {
    width: 36.79245%;
    margin-right: 26.41509%; }

  .grid--v-small__cols--1-2:nth-child(2n) {
    margin-right: 0; }
  .grid--v-small__cols--1-2:nth-child(2n +1) {
    clear: left; }

  /* Omega class */
  .-v-small__omega {
    margin-right: 0 !important; } }
@media only screen and (max-width: 500px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-mini__col--1,
  .grid--v-mini__cols--1 {
    width: 49.23077%;
    margin-right: 1.53846%; }

  .grid--v-mini__cols--1:nth-child(2n) {
    margin-right: 0; }
  .grid--v-mini__cols--1:nth-child(2n +1) {
    clear: left; }

  .-v-mini__offset--1 {
    margin-left: 50.76923%; }

  /* Subgrids */
  /* Omega class */
  .-v-mini__omega {
    margin-right: 0 !important; } }
