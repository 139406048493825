/* Form Select */
.form__select {
    display: block;
    font-size: 1.6rem;
    font-family: inherit;
    color: var(--color-black);
    line-height: normal;
    padding: .8em 1.4em .7em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid var(--color-gray-light3);
    box-shadow: 0;
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--color-white);
    background-image: url('../../images/select-arrow.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    &.-width-auto {
        width: auto;
    }

    .form__field.is-invalid & {
        border-color: var(--color-red);
    }

    &:focus {
        background-color: var(--color-white);
        border-color: var(--color-gray);
        outline: none;
    }
}
