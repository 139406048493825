@use '../helpers/mixins';
@import '~flatpickr/dist/flatpickr.css';

#datepicker {
    display: none;
}

.flatpickr-calendar {
    max-width: 35.6rem !important;
    width: 100%;
    margin: 0 0 0 auto;
    background-color: var(--color-gray-light);
    padding: 3.5rem 2.4rem;
    font-family: var(--font-primary);
    font-size: 1.6rem !important;
    letter-spacing: .07rem;

    @include mixins.screen-size(mini, small) {
        width: 100% !important;
        margin: 0;
    }
}

.flatpickr-innerContainer {
    overflow-x: auto;
}

.flatpickr-weekdaycontainer,
.dayContainer,
.flatpickr-days {
    max-width: 35.6rem !important;
    width: 100% !important;
}

.flatpickr-months {
    padding: 0 0 4.7rem;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    top: 3.5rem !important;

    svg {
        vertical-align: top;
    }

    &:hover,
    &:focus {
        svg {
            fill: var(--color-green) !important;
        }
    }
}

.flatpickr-prev-month {
    left: 2.4rem !important;
}

.flatpickr-next-month {
    right: 2.4rem !important;
}

.flatpickr-current-month {
    font-family: var(--font-secondary);
    font-size: 1.8rem;
}

.flatpickr-monthDropdown-months {
    -webkit-appearance: none !important;
}

.flatpickr-weekday {
    font-family: var(--font-primary);
    font-size: 1.6rem !important;
    letter-spacing: .07rem;
    max-width: 4.4rem !important;
}

.flatpickr-day {
    max-width: 4.4rem !important;
    height: 4.4rem;

    color: var(--color-black);
    line-height: 4.4rem !important;
    border: none;
    transition: all .3s ease-in-out;

    &.today {
        background-color: var(--color-white);
    }

    &.selected {
        background-color: var(--color-green);
    }
}

.flatpickr-day,
.flatpickr-day.selected {
    &:hover,
    &:focus {
        background-color: var(--color-gray-light3);
        color: var(--color-black) !important;
    }
}

//Select timeslot
.datepicker__timeslot__day {
    display: block;
    padding: 1.6rem;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-medium);
    font-size: 2rem;
}

.datepicker__timeslot__options {
    padding: 1.6rem;
    border: .1rem solid var(--color-gray-light);
}
