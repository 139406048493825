.info-box {
    background-color: var(--color-yellow);
    color: var(--color-brown);
    padding: .5em 1em .8em 2.2em;
    position: relative;
    font-size: 1.4rem;
    margin-bottom: .8em;

    &::before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        mask-image: url('../../images/info.svg');
        background-color: var(--color-orange);
        position: absolute;
        top: .7em;
        left: .5em;
    }

    a {
        color: currentColor;
        text-decoration: underline;

        &:hover, &:focus, &:active {
            text-decoration: none;
        }
    }
}
