@use '../helpers/mixins';

%figure {
    width: 100%;
    margin: 0.4rem 0 1rem;
    position: relative;

    img {
        margin-top: 0;
        width: 100%;
    }

    @include mixins.screen-size(medium, large) {
        &.-size-medium {
            width: 50%;
            margin-left: 0;
        }

        &.-size-small {
            width: 30%;
            margin-left: 0;
        }

        &.-align-left {
            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        &.-align-right {
            float: right;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }

    @include mixins.screen-size(mini, small) {
        width: 100%;
        margin-left: 0;
    }
}

.figure {
    @extend %figure;

    &.-align-center {
        text-align: center;
        margin: 0 auto 1rem;
    }

    @include mixins.screen-size(medium, large) {
        &.-align-left {
            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        &.-align-right {
            float: right;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }
}

/* Is used by the webadmin */
.figure--left {
    a.img,
    a.img--alt,
    img {
        @include mixins.screen-size(medium, large) {
            float: left;
            margin-right: 20px;
            margin-bottom: 20px;

        }
    }
}

.figure--right {
    a.img,
    a.img--alt,
    img {
        @include mixins.screen-size(medium, large) {
            float: right;
            margin-left: 20px;
            margin-bottom: 20px;

        }
    }
}

.figure--center {
    @include mixins.default-typography(margin);
    clear: both;
    a.img,
    a.img--alt,
    img {
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;

    }
}


figure { // inline images inserted by texteditor
    @include mixins.default-typography(margin);

    &.center {
        text-align: center;
    }

    @include mixins.screen-size(medium, large) {
        &.left img {
            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        &.right img {
            float: right;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }

    article &{
        @include mixins.default-typography(max-width);
        margin-left: auto;
        margin-right: auto;
    }
}
