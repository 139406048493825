.no-script {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid black;
    background-color: white;
    color: black;
    font-family: verdana;
    font-size: 11px;
    text-align: left;
    text-transform: uppercase;
}