@use '../helpers/mixins';

.footer {
    background-color: var(--color-black);
    color: var(--color-gray);
    font-size: 16px;
    overflow: hidden;

    &__top {
        padding: 60px 0;

        @include mixins.screen-size(mini, small, medium) {
            padding: 40px 0;
        }
    }

    &__nav {
        background-color: var(--color-primary);

        &:before {
            content: '';
            background-color: var(--color-primary);
            position: absolute;
            right: -60px;
            top: -60px;
            left: -5000px;
            height: 355px;

            @include mixins.screen-size(small) {
                height: 320px;
            }
            @include mixins.screen-size(mini) {
                height: 300px;
            }
        }

        a {
            color: var(--color-white);
            transition: all 0.1s ease-in-out;

            &:hover {
                padding-left: 3px;
            }
        }

        &:after {
            transition: all 0.2s ease-in-out;
            content:'';
            background-color: var(--color-black);
            position: absolute;
            transform: rotate(25deg);
            right: -126px;
            top: -52px;
            width: 200px;
            height: 600px;

            @include mixins.screen-size(medium) {
                transform: rotate(15deg);
                top: -60px;
                right: -140px;
            }

            @include mixins.screen-size(mini, small) {
                transform: rotate(15deg);
                top: -52px;
            }

            @include mixins.screen-size(mini) {
                right: -100px;
            }
        }

        @media only screen and (min-width: 1200px) {
            &:hover:after {
                transform: rotate(0deg);
                top: -60px;
                right: -170px;
            }
        }

        @include mixins.screen-size(mini) {
            margin-bottom: 3rem;
        }
    }

    &__title {
        font-size: 20px;
        color: var(--color-white);
        font-family: var(--font-secondary);
        font-weight: var(--font-weight-medium);
        margin-bottom: 1.2rem;
        display: block;
    }

    &__contact {
        margin-bottom: -1rem;

        a[href^=tel]:hover {
            color: var(--color-gray-light);
        }
    }

    &__openinghours {
        @include mixins.screen-size(mini, small) {
            margin-top: 2rem;
        }
    }

    &__social-media {
        @include mixins.screen-size(mini, small) {
            margin-top: 2rem;
        }

        a  {
            display: inline-block;
            margin-right: 7px;
            position: relative;

            @include mixins.screen-size(mini) {
                margin-left: 7px;
            }

            &:before {
                content: '';
                position: absolute;
                width: 90%;
                left: 5%;
                height: 90%;
                top: 5%;
                background-color: white;
                border-radius: 50%;
            }

            &:hover img {
                @include mixins.screen-size(large) {
                    transform: scale(1.2) rotate(15deg);
                }
            }
        }

        img {
            margin: 0;
            transition: all 0.2s ease-in-out;
        }

        @include mixins.screen-size(medium) {
            position: absolute;
            bottom: 20px;
        }
    }

    &__logo {
        margin-top: 128px;

        @include mixins.screen-size(medium) {
            display: none;
        }

        @include mixins.screen-size(mini) {
            margin-top: 2rem;
            max-width: none;
            display: block;
        }
    }

    &__bottom {
        border-top: 1px solid var(--color-gray-dark);
        transition: all 0.2s ease-in-out;

        @include mixins.screen-size(large) {
            &:hover {
                border-top: 1px solid var(--color-gray2);
            }
        }

        &-nav {
            padding: 22px 0;
            position: relative;

            a {
                @include mixins.screen-size(mini) {
                    display: block;
                    margin-top: 5px;
                }

                &:before {
                    content: '';
                    border-left: 1px solid var(--color-gray2);
                    width: 1px;
                    margin: 0 10px 0 8px;

                    @include mixins.screen-size(mini) {
                        display: none;
                    }
                }

                @include mixins.screen-size(large) {
                    &.-credits {
                        position: absolute;
                        right: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
