@use '../../helpers/mixins';

/* Titles */
%small-line-after {
    content: '';

    position: relative;

    display: block;

    max-width: 75px;
    height: 1px;
    margin: 0 auto;
    padding-block-end: 1rem;

    border-block-end: 1px solid var(--color-gray-light3);
}

%title {
    @include mixins.default-typography(margin, max-width);

    display: block;

    font-family: var(--font-secondary);
    font-weight: var(--font-weight-medium);
    line-height: 1.25;
    color: var(--color-gray2);

    article & {
        margin-inline: auto;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    &.-line {
        padding-block-end: 1rem;
        border-block-end: 1px solid var(--color-gray-light3);
    }

    &.-small-line::after {
        @extend %small-line-after;
    }

    &.-no-margin {
        margin-block-end: 0;
    }

    &.-align-center {
        margin-inline: auto;
        text-align: center;
    }
}

.title--1 {
    @extend %title;

    @include mixins.screen-size(small) {
        font-size: 3rem;
    }

    @include mixins.screen-size(mini) {
        font-size: 2.5rem;
    }

    font-size: 3.5rem;
    line-height: 1;
    text-transform: uppercase;
}

h1 {
    @extend .title--1;
}

.title--2 {
    @extend %title;

    font-size: 2.6rem;
    font-weight: var(--font-weight-regular);
    text-transform: uppercase;

    b {
        display: block;

        margin-block-end: 3px;

        font-size: 4rem;
        font-weight: var(--font-weight-extra-bold);
        line-height: 1;
        color: var(--color-primary);
    }

    &.-smaller {
        font-size: 2.2rem;
    }
}

.title--2b {
    @extend %title;

    font-size: 2.6rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
}

.title--3 {
    @extend %title;

    font-size: 2rem;

    &.-arrow {
        > a {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &::after {
                content: '';

                display: inline-block;

                width: 7px;
                height: 12px;
                margin-inline-start: 10px;

                background: url(../../../images/gray-arrow.svg) no-repeat;
                background-size: cover;
            }
        }
    }
}

h2 {
    @extend .title--3;
}

.title--3b {
    @extend .title--3;

    font-weight: var(--font-weight-bold);
    color: var(--color-black);
}

.title--4 {
    @extend %title;

    font-size: 1.8rem;
    color: var(--color-primary);
}

h3 {
    @extend .title--4;
}

h4, h5, h6 {
    @extend %title;
}

.title--meta {
    display: block;

    margin-block: 4px 2rem;

    font-size: 1.4rem;
    line-height: 1;
    color: var(--color-gray);
}

.title--full {
    @extend .title--3;

    font-weight: var(--font-weight-extra-bold);
    color: black;
    text-transform: uppercase;
}
