@use '../helpers/mixins';
/* ==========================================================================
   Global layout: header & footer
   ========================================================================== */

html, body {
    min-width: 320px;
    width: 100%;
    font-family: var(--font-primary);
}

html {
    color: var(--default-text-color);
    font-size: 62.5%; // Work with 10px scale for rems
}

body {
    @include mixins.font-smoothing(on);
    @include mixins.default-typography(font-size, line-height);
    position: relative;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    min-width: 320px;

    // Don't let long words break the layout on small area's
    word-wrap: break-word;
}
