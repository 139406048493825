@use '../helpers/mixins';
@use '../variables';

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.h-hidden {
    display: none !important;
    visibility: hidden;
}

.h-visible {
    display: block !important;
    visibility: visible;
}

@each $screen-size, $screen-size-config in variables.$screen-sizes {
    @include mixins.screen-size($screen-size) {
        .h-v-#{$screen-size}__hidden {
            display: none !important;
            visibility: hidden;
        }
    }
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 * Allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
 */
%h-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.h-visuallyhidden {
    @extend %h-visuallyhidden;
}

.h-visuallyhidden--focusable {
    @extend %h-visuallyhidden;

    &:active, &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}
