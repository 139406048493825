.title-icon--category {
    &:before {
        content: '';
        display: inline-block;
        background-image: url(../../images/category.svg);
        background-size: cover;
        height: 1em;
        margin-right: 0.5em;
        width: 1.2em;
    }
}
