@use '../../helpers/mixins';

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0 0 0 1.5em;

    li {
        margin: 0;
        padding: 0;
    }
}

%list {
    @include mixins.default-typography(margin, max-width);
    overflow: hidden;

    article &{
        margin-left: auto;
        margin-right: auto;
    }

    li {
        display: list-item;
        margin-bottom: 0.5em;
    }
}

// Lists without class can get a default styling
ul:not([class]), ol:not([class]) {
    @extend %list;
}

%transition {
    li {
        &:before {
            transition: all 0.1s ease-in-out;
        }

        &:hover:before {
            transform: scale(1.2) rotate(5deg);
        }
    }
}

%ul {
    li {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            background-repeat: no-repeat;
            background-size: cover;
            left: -1em;
            color: var(--color-gray2);
        }
    }
}

ul:not([class]) {
    @extend %ul;
    @extend %transition;

    li {
        &:before {
            background-image: url(../../../images/gray-arrow.svg);
            width: 7px;
            height: 12px;
            top: 5px;
        }
    }
}

ul.-white {
    @extend %list;
    @extend %ul;
    @extend %transition;

    li {
        color: var(--color-white);

        &:before {
            background-image: url(../../../images/white-arrow.svg);
            width: 7px;
            height: 12px;
            top: 5px;
        }
    }
}

ul.-ticks {
    @extend %list;
    @extend %ul;
    @extend %transition;

    padding-left: 0;

    li {
        margin-bottom: 1em;
        padding-left: 2em;

        &::before {
            background-image: url(../../../images/tick.svg);
            width: 17px;
            height: 12px;
            top: 5px;
            left: 0;
        }
    }
}

ol:not([class]) {
    list-style-type: decimal;
}
