.form__table {
    display: grid;
    gap: 2rem;

    > div {
        display: grid;
        gap: 2rem;
        grid-template-columns: 3fr 1fr 4.5em;
        align-items: center;
    }
}
