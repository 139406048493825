@use '../helpers/mixins';

.facilities {
    display: flex;

    @include mixins.screen-size(mini) {
        display: block;
    }

    > * {
        @include mixins.screen-size(small, medium, large) {
            width: calc(50% - 1rem);
            margin-right: 2rem;
        }
        margin-bottom: 2rem;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}
