@use '../../node_modules/@inventis/cookieconsent/src/cookie-consent.scss';

:root {
    --cookie-consent-options-background-color: var(--color-gray-lighter);
}

.cookie-consent__options {
    label {
        font-weight: bold;
    }

    input[type='checkbox'] {
        margin-inline-end: .5rem;
    }
}

.cookie-consent__option-description {
    font-weight: normal;
}
