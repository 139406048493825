@use '../helpers/mixins';

.form__field.form__field--search-date {
    background: transparent;
    color: var(--color-white);
    border: 0;
    padding: 0;

    &:focus {
        background: transparent;
    }

    &::-webkit-calendar-picker-indicator {
        background-image: none;
        margin-right: -2.6rem;
        z-index: 1;
    }

    &::after {
        content: '';
        display: inline-block;
        margin-left: .8rem;
        background-image: url("../../images/select-arrow-white.svg");
        background-size: 1.4rem .8rem;
        width: 1.4rem;
        height: .8rem;
        transition: all .3s ease-in-out;

        @include mixins.screen-size(mini, small, medium) {
            position: relative;
            right: 0;
        }
    }
}
