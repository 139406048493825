@use '../helpers/mixins';

$properties: margin-bottom margin-top;

@each $property in $properties {
    @for $i from 0 through 40 {
        .h-#{$property}--#{$i} {
            #{$property}: $i * 1rem;
            @include mixins.screen-size(mini, small) {
                #{$property}: $i * 0.5rem;
            }
        }
    }
}

.h-margin-right--0 {
    margin-right: 0;
}

.h-margin-right--1 {
    margin-right: 1rem;
}

.h-margin-right--05 {
    margin-right: .5rem;
}
