@use '../../helpers/mixins';

strong, b {
    font-weight: var(--font-weight-bold);
}

p {
    @include mixins.default-typography(margin);

    &.summary {
        font-size: 120%;
    }

    article &{
        @include mixins.default-typography(max-width);
        margin-left: auto;
        margin-right: auto;
    }
}

blockquote {
    @include mixins.default-typography(margin, max-width);

    font-style: italic;
    font-size: 120%;
}

article {
    overflow: hidden;
}

small {
    color: var(--color-gray);
}

.icon-paragraph {
    padding-left: 2.5rem;
    margin-bottom: .5em;
    position: relative;

    &::before {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 1.5rem;
        left: 0;
        position: absolute;
        top: .4rem;
        width: 1.5rem;

        @supports (mask-image: url('../../../images/location.svg')) {
            background-color: var(--color-primary);
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &.-location::before {
        width: 1.3rem;
        height: 1.6rem;
        background-image: url('../../../images/location.svg');

        @supports (mask-image: url('../../../images/location.svg')) {
            background-image: none;
            mask-image: url('../../../images/location.svg');
        }
    }

    &.-phone::before {
        background-image: url('../../../images/phone.svg');

        @supports (mask-image: url('../../../images/phone.svg')) {
            background-image: none;
            mask-image: url('../../../images/phone.svg');
        }
    }

    &.-mail::before {
        width: 1.6rem;
        height: 1.3rem;
        top: .5rem;
        background-image: url('../../../images/mail.svg');

        @supports (mask-image: url('../../../images/mail.svg')) {
            background-image: none;
            mask-image: url('../../../images/mail.svg');
        }
    }
}
