:root {
    --popup-bottom: 50%;
    --popup-max-width: 42em;
    --popup-transform: translate3d(0, calc(50% + .5px), 0); // Fix blurry rendering in Chrome
    --popup-padding: 2em;
    --popup-background-color: white;
}

.popup {
    position: fixed;
    inset-block-end: var(--popup-bottom);
    transform: var(--popup-transform);

    overflow-y: auto;

    box-sizing: border-box;
    width: 100%;
    max-width: var(--popup-max-width);
    max-height: 100%;
    margin: auto auto 0;
    padding: 0;

    background-color: var(--popup-background-color);
    border: 0;

    p {
        margin-block-end: 2em;

        &:last-child {
            margin-block-end: 0;
        }
    }

    &__inner {
        padding: var(--popup-padding);
    }

    &__image {
        display: block;
        width: 100%;
        margin: 0;
    }

    &__close {
        cursor: pointer;

        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;

        width: 4rem;
        height: 4rem;

        color: var(--color-black);
        text-transform: lowercase;

        background-color: var(--color-white);
        border: 0;

        &::after {
            content: '';

            display: block;

            width: 100%;
            height: 100%;

            background-color: currentcolor;

            mask-image: url('data:image/svg+xml;utf8,<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>');
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 2rem;
        }

        &:hover, &:focus, &:active {
            background-color: var(--color-gray-light);
        }
    }

    @media (max-width: 730px), (max-height: 500px) {
        --popup-bottom: 0;
        --popup-transform: none;
        --popup-max-width: none;
    }
}
