.image-box {
    > a {
        display: block;
    }

    &__content {
        background-color: var(--color-gray-lighter);
        padding: 2rem;

        > .button {
            margin-top: 2.5rem;
        }
    }
}
