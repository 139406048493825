@use '../helpers/mixins';

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.h-clearfix {
    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }
    &:after {
        clear: both;
    }

    @include mixins.screen-size(medium, large) {
        .h-float-left {
            float: left;
        }

        .h-float-right {
            float: right;
        }
    }
}

.h-clear {
    clear: both;
}
