/* Form Message */
.form__message {
    display: block;

    color: var(--color-red);
    padding: 5px;

    margin-top: 0;

    font-size: 13px;
    line-height: 1;
    font-weight: 700;

    .form__entry &{
        display: none;
    }

    [aria-invalid] + &, &.-invalid {
        display: block;
    }
}

.form__field--textarea + .form__message {
    margin-top: -10px;
}

.form__message .icon,
.form__message--icon:before {
    padding-right: 5px;
}
