@use '../helpers/mixins';

// Defaults & resets
%form__field--base-reset {
    font-size: 1.6rem;
    line-height: normal;
    margin: 0;
    width: 100%;
    padding: 1rem;
}

%form__field--full-reset {
    @extend %form__field--base-reset;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS
    border-radius: 4px;

    box-sizing: border-box;
    display: inline-block;
    padding: 1.2rem;
    word-break: normal;
    border: 1px solid var(--color-gray-light3);

    background-color: var(--color-white);
    color: var(--color-black);

    @include mixins.input-placeholder() {
        color: var(--color-gray);
    }

    &:focus {
        background-color: var(--color-white);
        border-color: var(--color-gray);
        outline: none;
    }

    &[aria-invalid] {
        border-color: var(--color-red);

        @include mixins.input-placeholder() {
            color: var(--color-white);
        }
    }
}

.form__field--date,
.form__field--email,
.form__field--file,
.form__field--number,
.form__field--password,
.form__field--search,
.form__field--tel,
.form__field--text,
.form__field--textarea,
.form__field--time {
    @extend %form__field--full-reset;
}
