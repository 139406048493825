.pagination-select {
    position: relative;

    &__next {
        margin-left: 1em;

        &:after {
            content: '>';
            display: inline-block;
            margin-left: 5px;
            color: var(--color-black);
            font-size: 110%;
        }
    }

    &__prev {
        margin-right: 1em;

        &:before {
            content: '<';
            display: inline-block;
            margin-right: 5px;
            color: var(--color-black);
            font-size: 110%;
        }
    }

    &__all {
        position: absolute;
        right: 0;
    }
}
