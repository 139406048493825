@use '../../helpers/mixins';

:root {
    --table-border-color:                        transparent;

    --table-head-color:                          var(--default-text-color);
    --table-head-background-color:               #e9e9e9;

    --table-row-color:                           var(--default-text-color);
    --table-row-background-color-even:           #f9f9f9;
    --table-row-background-color-odd:            var(--color-white);
}

table:not([class]) {
    @include mixins.default-typography(margin);

    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    article &{
        margin-left: auto;
        margin-right: auto;
    }

    tr {
        &:nth-child(even) {
            background-color: var(--table-row-background-color-even);
        }
        &:nth-child(odd) {
            background-color: var(--table-row-background-color-odd);
        }
    }

    th, td {
        color: var(--table-row-color);
        padding: 0.5rem;
        border: 1px solid var(--table-border-color);
    }

    th {
        color: var(--table-head-color);
        background: var(--table-head-background-color);
        vertical-align: middle;
        text-align: center;
    }

    tbody {
        th, td {
            vertical-align: top;
            text-align: left;
        }
    }

    ul, ol {
        padding-left: 1rem;
    }

    @include mixins.screen-size(mini, small, medium) {
        display: block;
        position: relative;
        border-collapse: collapse;
        border-spacing: 0;

        th, td {
            margin: 0;
            vertical-align: top;

            &:last-child {
                border-bottom: 1px solid var(--table-border-color);
            }
        }

        th {
            text-align: left;
            display: block;
            border-right: 0;
        }

        thead {
            display: block;
            float: left;

            tr {
                display: block;
                margin: 0;
                padding: 0;
            }
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;

            tr {
                display: inline-block;
                vertical-align: top;
                border-left: 1px solid var(--table-border-color);
            }
        }

        td {
            display: block;
            min-height: 1.25em;
            text-align: left;
        }

        th {
            border-bottom: 0;
            border-left: 0;
        }

        td {
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
        }
    }
}

.table--transparent {
    @include mixins.default-typography(margin);

    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
        > *:first-child {
            padding-left: 0;
        }

        > *:last-child {
            padding-right: 0;
        }
    }

    th, td {
        color: var(--table-row-color);
        padding: .5rem;

        &.-align-right {
            text-align: right;
        }

        &.-align-bottom {
            vertical-align: bottom;
        }

        > span {
            color: var(--color-gray2);
            font-style: italic;
            font-weight: normal;
        }

        small {
            font-size: 1.1rem;
            color: var(--color-gray);
            font-weight: var(--font-weight-regular);

            &.-move-up {
                position: relative;
                top: -15px;
            }
        }
    }

    th {
        color: var(--table-head-color);
        vertical-align: middle;
        text-align: center;
    }

    tbody, tfoot {
        th, td {
            vertical-align: top;
            text-align: left;
        }
    }

    tfoot {
        border-top: 1px solid var(--color-gray-light3);

        th, td {
            font-size: 2rem;
            font-weight: var(--font-weight-bold);
            color: var(--color-black);
        }
    }

    ul, ol {
        padding-left: 1rem;
    }
}

table.-text-align-left {
    th, td {
        text-align: left;
    }
}
