@use '../../helpers/mixins';

hr {
    @include mixins.default-typography(margin);
    margin-top: 2rem;
    display: block;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: 1px solid var(--color-gray-light2);

    article &{
        margin-left: auto;
        margin-right: auto;
    }
}
