$selection-colors-backgroundcolor: var(--color-green); // Browser default (chrome)
$selection-colors-color: var(--color-white);



::-moz-selection {
    background-color: $selection-colors-backgroundcolor;
    color: $selection-colors-color;
    text-shadow: none;
}

::selection {
    background-color: $selection-colors-backgroundcolor;
    color: $selection-colors-color;
    text-shadow: none;
}
