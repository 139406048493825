@use '../../helpers/mixins';

details {
    @include mixins.default-typography(max-width);
    border: 1px solid var(--color-gray-light3);
    margin: 0 auto 5rem;
    padding: 1.35em 2em .35em 1em;

    > summary {
        cursor: pointer;
        list-style: none;
        position: relative;
        color: var(--color-gray2);

        &:hover, &:active {
            color: var(--color-green);

            &::after {
                color: inherit;
            }
        }

        &:focus {
            text-decoration: underline;
            outline: none;
        }

        > h2:not([class]), > h3:not([class]), > h4:not([class]) {
            color: inherit;
        }

        &::-webkit-details-marker {
            display: none;
        }

        &::-moz-list-bullet {
            list-style-type: none;
            display: block;
        }

        &::after {
            content: '';
            display: block;
            background-image: url('../../../images/gray-arrow.svg');
            background-size: 8px 16px;
            width: 8px;
            height: 16px;
            color: var(--color-gray-light3);
            position: absolute;
            right: -1em;
            top: .5rem;
            transform: rotate(90deg);
            transform-origin: center;
            transition: .2s transform ease-in-out;

            @supports (mask-image: url('../../../images/gray-arrow.svg')) {
                mask-image: url('../../../images/gray-arrow.svg');
                mask-size: 8px 16px;
                background-color: currentColor;
                background-image: none;
            }
        }
    }

    + details {
        border-top: 0;
        margin-top: -5rem;
    }

    &[open] {
        background-color: var(--color-gray-lighter);

        summary {
            color: var(--color-green);

            &:focus {
                text-decoration: none;
            }

            &::after {
                color: inherit;
                transform: rotate(-90deg);
            }
        }
    }
}
