@use '../variables';

@mixin clearfix {
    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }
    &:after {
        clear: both;
    }
}

@mixin default-typography($properties...) {
    @each $screen-size, $screen-size-config in variables.$screen-sizes {
        @include screen-size($screen-size) {
            $typography-config: map-get($screen-size-config, typography);
            @each $property in $properties {
                @if map-has-key($typography-config, $property) {
                    #{$property}: map-get($typography-config, $property);
                } @else {
                    @warn "No default typography property found for '#{$property}' for screen size '#{$screen-size}'";
                }
            }
        }
    }
}

// More info: http://css-tricks.com/snippets/css/using-font-face/
@mixin font-face($filename, $svgId: $filename, $name: $filename) {
    @font-face {
        font-family: '#{$name}';
        src: url('#{$filename}.eot'); /* IE9 Compat Modes */
        src: url('#{$filename}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$filename}.woff2') format('woff2'), /* Super Modern Browsers */
        url('#{$filename}.woff') format('woff'), /* Pretty Modern Browsers */
        url('#{$filename}.ttf') format('truetype'), /* Safari, Android, iOS */
        url('#{$filename}##{$svgId}') format('svg'); /* Legacy iOS */
    }
}

/**
 * You can use font-smoothing best when using light text on a dark background
 * This only works on Mac OS X
 *
 * More info:
 * - http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
 * - http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
@mixin font-smoothing($value: on) {
    @if $value == on {
        // Light on dark
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        // Reset on higher pixel density screens
        @media
        only screen and (-webkit-min-device-pixel-ratio: 1.25),
        only screen and ( min-device-pixel-ratio: 1.25),
        only screen and ( min-resolution: 200dpi),
        only screen and ( min-resolution: 1.25dppx) {
            -webkit-font-smoothing: subpixel-antialiased;
            -moz-osx-font-smoothing: auto;
        }
    }
    @else {
        // Dark on light
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    // for Firefox 19 and below
    &:-moz-placeholder {
        @content;
    }

    // for Firefox 20 and above
    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &.placeholder {
        @content;
    }
}

@mixin screen-size($sizes...) {
    @each $size in $sizes {
        @each $screen-size, $screen-size-config in variables.$screen-sizes {
            $grid-config: map-get($screen-size-config, grid);
            $breakpoint: map-get($grid-config, breakpoint);

            @if $size == $screen-size {
                @if map-has-key($grid-config, screen-min-width) and map-has-key($grid-config, screen-max-width) {
                    @media only screen and (min-width: map-get($grid-config, screen-min-width)) and (max-width: map-get($grid-config, screen-max-width)) {
                        @content;
                    }
                } @else if map-has-key($grid-config, screen-min-width) {
                    @media only screen and (min-width: map-get($grid-config, screen-min-width)) {
                        @content;
                    }
                } @else if map-has-key($grid-config, screen-max-width) {
                    @media only screen and (max-width: map-get($grid-config, screen-max-width)) {
                        @content;
                    }
                } @else {
                    @content;
                }
            }
        }
    }
}

@mixin text-color($color) {
    color: $color;
    a { color: $color; }
}

@mixin triangle($direction: up, $color: black, $size: .6rem) {
    display: inline-block;
    height: 0;
    width: 0;

    @if $direction == left {
        border-top: $size solid transparent;
        border-right: $size solid $color;
        border-bottom: $size solid transparent;
        border-left: 0;
    }
    @else if $direction == right {
        border-top: $size solid transparent;
        border-right: 0;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    }
    @else if $direction == up {
        border-top: 0;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    }
    @else if $direction == down {
        border-top: $size solid $color;
        border-right: $size solid transparent;
        border-bottom: 0;
        border-left: $size solid transparent;
    }
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}
