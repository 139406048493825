@use '../helpers/mixins';

.unit-slider {
    position: relative;
    padding-top: 5rem;

    > output {
        background-color: var(--color-black);
        border-radius: 3px;
        color: var(--color-white);
        cursor: default;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1;
        margin-left: 14px;
        padding: 1rem;
        position: absolute;
        top: 0;
        transform: translateX(-50%);

        &::after {
            @include mixins.triangle(down, var(--color-black), .6rem);
            bottom: -.6rem;
            content: '';
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }
}

.unit-slider__actions {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
}
