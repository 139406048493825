@use '../helpers/mixins';

%form__label {
    display: inline-block;
    cursor: pointer;
    color: var(--color-gray2);
    font-size: 15px;
    line-height: 1.2;
    margin: 0 0 .5em;

    @include mixins.screen-size(mini, small) {
        display: block;
    }
}

.form__label {
    @extend %form__label;
}

.form__label--checkbox,
.form__label--radio {
    @extend %form__label;
    color: var(--color-black);
    cursor: pointer;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 1;
    margin-bottom: .4em;
    padding-left: 1.8em;
    padding-right: 1.5em;
    position: relative;
    vertical-align: bottom;

    &.-aligned {
        display: flex;
        align-items: center;

        > img {
            margin: 0 1em 0 .5em;
            max-height: 3rem;
            height: 100%;

            &[alt='BCMC'], &[alt='ING HomePay'], &[alt='KBC Online'], &[alt='CBC Online'], &[alt='Belfius Direct Net'] {
                max-height: 4rem;
            }

            &[alt='VISA'] {
                max-height: 2.5rem;
            }
        }

        &::before {
            top: auto;
        }

        &::after {
            .form__field--radio:checked + & {
                top: auto;
            }
        }
    }
}

.form__label--sublabel {
    color: var(--color-gray);
    font-size: 14px;
    padding-left: 2em;
    max-width: 37em;
}
