@use '../helpers/mixins';

.unit-block {
    background-color: var(--color-gray-lighter);
    margin-bottom: 1em;
    align-items: stretch;
    border: 2px solid transparent; // Used so it doesn't jump when the border is added for bestseller

    &.-bestseller {
        border-color: var(--color-black);
    }

    .product__image-promo {
        inset-inline: auto 0;
    }

    @media (min-width: 700px) {
        display: flex;
        flex-flow: row-reverse;
    }

    img {
        margin-top: 0;
    }

    &__image {
        flex: 1 0 40%;
        position: relative;

        > img {
            margin-top: 0;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &.-image-top {
        @include mixins.screen-size(medium, large) {
            display: block;
        }
    }

    &__info {
        padding: 2rem;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-content: flex-start;

        @media (max-width: 499px) {
            display: block;
        }
    }

    &__price {
        color: var(--color-primary);
        font-size: 1.8rem;
        padding-top: .9rem;
        position: relative;
        top: -1em;

        @media (min-width: 500px) {
            text-align: right;
        }

        > small {
            display: block;
        }
    }

    &__action {
        color: var(--color-primary);
        font-size: 1.4rem;

        &::after {
            content: '';
            display: inline-block;
            margin-left: .5em;
        }

        &.-edit {

            &::after {
                background-image: url('../../images/pensel.svg');
                width: 12px;
                height: 12px;
            }
        }
    }
}
