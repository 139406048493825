@use '../helpers/mixins';
@use '../base/typography/title';
@use '../variables';

.title-bar {
    text-align: center;
    background-color: var(--color-black);
    position: relative;
    margin-bottom: 4rem;
    margin-top: -5rem;

    @include mixins.screen-size(large) {
        margin-top: -6rem;
    }

    @media only screen and (max-width: variables.$tweakpoint-title-bar) {
        text-align: left;
    }

    @include mixins.screen-size(mini, small) {
        margin-bottom: 2rem;
    }

    &__container {
        position: relative;
        padding: 43px 0;
        overflow: hidden;

        @media only screen and (max-width: variables.$tweakpoint-title-bar) {
            padding: 33px 0;
        }
    }

    .grid__container {
        @media only screen and (min-width: variables.$breakpoint-medium+1) and (max-width: variables.$tweakpoint-title-bar) {
            padding: 0 4%;
        }
        @include mixins.screen-size(medium) {
            padding: 0 11% 0 160px;
        }
        @include mixins.screen-size(mini, small) {
            padding: 0 2em;
        }
    }

    &__title {
        @extend .title--1;
        @include mixins.font-smoothing(on);
        margin: 0 auto;
        color: var(--color-white);
        max-width: 700px;

        &:before {
            content: '';
            width: 1000px;
            background-color: var(--color-green);
            border-top: 24px solid var(--color-white);
            border-bottom: 3000px solid var(--color-white);
            height: 32px;
            transform: rotate(120deg);
            position: absolute;
            left: -1750px;
            top: -1910px;
            z-index: 2;
            @media only screen and (min-width: variables.$tweakpoint-title-bar+1) {
                animation: move-line .7s ease forwards;
            }
            @media only screen and (max-width: variables.$tweakpoint-title-bar) {
                top: -2200px;
                height: 22px;
                border-top-width: 15px;
            }
            @include mixins.screen-size(mini, small) {
                display: none;
            }
        }
    }

    &__sub-title {
        color: var(--color-white);
        font-size: 2rem;
        margin-left: 20px;
    }
}

@keyframes move-line {
    from {
        border-top-width: 0px;
        left: -1740px;
    }
    to {
        border-top-width: 24px;
        left: -1750px;
    }
}

.title-bar--homepage {
    position: relative;
    overflow: hidden;
    margin-top: -5rem;

    @include mixins.screen-size(large) {
        margin-top: -6rem;
        max-width: 1900px;
        margin-inline: auto;
    }

    .grid__container {
        padding: 0;

        @include mixins.screen-size(large) {
            padding-left: 50px;
            padding-right: 50px;

            display: flex;
            align-items: center;
            position: unset;
        }

        @media only screen and (min-width: variables.$breakpoint-small) {
            height: 41.5rem;
        }
    }

    .title-bar__container {
        height: 100%;
        z-index: 1;

        padding: 6.7rem 1.6rem 3.7rem;

        @media only screen and (min-width: variables.$breakpoint-small) {
            display: flex;
            align-items: center;

            max-width: 40%;
            position: relative;
            overflow: visible;
            padding: 7rem 1.6rem;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: -50%;
                background-color: var(--color-white);
                width: 175%;
                height: 100%;
                transform: skewX(-20deg);
                z-index: 1;
            }
        }

        @media only screen and (min-width: variables.$tweakpoint-title-bar) {
            max-width: 66rem;
            padding: 7rem 0 11rem;

            &::after {
                width: 155%;
            }
        }
    }

    .title-bar__title {
        color: var(--color-black);
        font-size: 2.8rem;
        font-weight: var(--font-weight-extra-bold);
        margin: 0 auto 0.8rem 0;
        z-index: 2;

        @media only screen and (min-width: variables.$breakpoint-mini) {
            font-size: 3.5rem;
        }

        @media only screen and (min-width: variables.$breakpoint-medium) {
            font-size: 5rem;
        }

        &:before {
            display: none;
        }

        b {
            color: var(--color-primary);
        }
    }

    .title-bar__image {
        display: flex;
        align-items: center;
        max-height: 20rem;
        overflow: hidden;

        @media only screen and (min-width: variables.$breakpoint-mini) {
            max-height: 30rem;
        }

        @media only screen and (min-width: variables.$breakpoint-small) {
            max-height: 46rem;
            position: absolute;
            top: 0;
            right: -20rem;
        }

        @media only screen and (min-width: variables.$tweakpoint-title-bar) {
            right: 0;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
