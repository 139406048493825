@use '../helpers/visibibiltiy';

/* form__FIELDSET */
.form__fieldset {
    border: 0;
    margin: 0 0 .5em;
    padding: 0;
}

.form__fieldset--radio-buttons {
    display: inline-flex;

    > input {
        @extend %h-visuallyhidden;

        + label {
            background-color: var(--color-gray-lighter);
            border: 1px solid var(--color-gray-light3);
            border-left-width: 0;
            border-right-width: 0;
            color: var(--color-gray);
            font-size: 1.6rem;
            padding: 1.5rem 2rem 1.7rem;
            cursor: pointer;
        }

        &:first-of-type {
            + label {
                border-left-width: 1px;
                border-radius: 5px 0 0 5px;
            }
        }

        &:last-of-type {
            + label {
                border-right-width: 1px;
                border-radius: 0 5px 5px 0;
            }
        }

        &:checked {
            + label {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}
