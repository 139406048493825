%form__row {
    display: grid;
    gap: 0 1em;
    align-items: baseline; // Needed to align labels (when going over multiple lines) and error messages
}

.form__row {
    @extend %form__row;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
}

.form__row--3-1 {
    @extend %form__row;
    grid-template-columns: minmax(15em, 3fr) minmax(5em, 1fr);
}

.form__row--1-2 {
    @extend %form__row;
    grid-template-columns: minmax(5em, 1fr) minmax(15em, 2fr);
}
