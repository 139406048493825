@use '../helpers/mixins';
@use '../variables';

%box {
    margin-block-end: var(--default-block-margin);
    padding: var(--default-block-padding);

    > *:last-child {
        margin-block-end: 0;
    }

    table {
        width: 100%;
    }

    p {
        margin-block-end: 1rem;
    }

    &.-small-top-padding {
        padding-block-start: 1rem;
    }
}

.box {
    @extend %box;

    padding-block: 0;
}

.box--gray {
    @extend %box;

    color: var(--color-gray2);
    background-color: var(--color-gray-lighter);
}

.box--product {
    @extend .box--gray;

    padding-inline: 5cqw;
    text-align: center;

    .title--3b {
        text-align: start;
    }

    .-bestseller & {
        border: 2px solid var(--color-black);
        border-top: 0;
    }

    &__image {
        display: block;

        .-bestseller & {
            border: 2px solid var(--color-black);
            border-bottom: 0;
        }
    }
}

.box--category {
    @extend %box;

    @include mixins.screen-size(mini, small) {
        margin-block-end: 3rem;
        padding: 0;
    }

    padding-block-start: 0;

    h2 {

    }

    ul {
        margin-block-end: 1rem;
    }
}

.box--booking {
    max-width: 782px;
    margin: 0 auto;
    padding: 4rem min(4vw, 4rem);
    border: 1px solid var(--color-gray-light2);

    &__separator {
        margin: 2em -4rem;
    }
}

.box--advantages {
    order: 1;
    margin-block-start: 1rem;

    @media only screen and (min-width: variables.$tweakpoint-title-bar--homepage) {
        order: 0;
        margin-block-start: 0;

        > picture {
            display: none;
        }
    }

    @media only screen and (min-width: variables.$breakpoint-medium) {
        ul {
            column-count: 2;
        }

        ul > li {
            margin-inline-end: 5rem;
        }
    }
}
