@use '../variables';

.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 20.2rem;
    position: relative;
    width: 100%;
    margin-bottom: .8rem;
    overflow: hidden;

    > p {
        margin: 0 2rem 2rem;
        color: var(--color-white);
        font-family: var(--font-secondary);
        font-weight: var(--font-weight-semi-bold);
        font-size: 2rem;
        line-height: 1.25;
    }

    > img {
        display: block;
        height: 100%;
        margin: 0;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        transition: opacity .2s ease-in-out;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;

        > img {
            opacity: .9;
        }
    }

    @media only screen and (min-width: variables.$breakpoint-medium) {
        height: 23.2rem;
        width: 41.3rem;
        margin-bottom: 2rem;
    }
}

.card__tag {
    color: var(--color-green);
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-bold);
    font-size: 1.4rem;
    text-transform: uppercase;

    background-color: var(--color-white);
    padding: .5rem .8rem;
    margin: 0 0 .5rem 2rem;
}
