@use '../helpers/mixins';

.dropdown {
    position: relative;
    display: inline-block;

    > .form__label {
        display: none;

        @include mixins.screen-size(mini, small, medium) {
            display: block;
        }
    }

    &.-open {
        .dropdown__options {
            display: block;
        }

        .dropdown__selected {
            .dropdown__selected__info::after {
                transform: rotate(180deg);
            }
        }
    }
}

.dropdown__selected {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.2rem 1.3rem 1.3rem;

    @include mixins.screen-size(large) {
        min-width: 33rem;
    }

    @include mixins.screen-size(mini, small, medium) {
        background-color: var(--color-gray3);

        .form__label {
            display: none;
        }
    }

    &:hover,
    &:focus {
        background-color: transparent;

        @include mixins.screen-size(mini, small, medium) {
            background-color: var(--color-gray2);
        }
    }

    > img {
        width: 7.9rem;
        height: 7.9rem;
        margin: 0 1.4rem 0 0;

        @include mixins.screen-size(mini, small, medium) {
            width: 3.8rem;
            height: 3.8rem;
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        color: var(--color-white);
        width: 100%;
    }
}

.dropdown__selected__info {
    max-width: 25rem;

    @include mixins.screen-size(mini, small, medium) {
        position: relative;
        display: flex;
        align-items: center;
        max-width: none;
    }

    &::after {
        content: '';
        display: inline-block;
        margin-left: .8rem;
        background-image: url("../../images/select-arrow-white.svg");
        background-size: 1.4rem .8rem;
        width: 1.4rem;
        height: .8rem;
        transition: all .3s ease-in-out;

        @include mixins.screen-size(mini, small, medium) {
            position: absolute;
            right: 0;
        }
    }
}

.dropdown__options {
    display: none;
    width: 100%;
    position: absolute;
    top: 10.4rem;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    max-height: 50vh;
    overflow: scroll;

    @include mixins.screen-size(mini, small, medium) {
        top: 8rem;
    }
}

.dropdown__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    border: .1rem solid var(--color-gray-light3);
    border-top: none;
    padding: .8rem 2rem .8rem 1rem;
    color: var(--default-text-color);
    font-family: var(--font-primary);
    transition: all .3s ease-in-out;
    cursor: pointer;
    position: relative;

    @include mixins.screen-size(mini, small, medium) {
        margin: 0 1.8rem;
    }

    span {
        display: flex;
        align-items: center;
    }

    img {
        width: 9.6rem;
        height: 8.6rem;
        margin: -.8rem 1.2rem -.8rem -1rem;
        object-fit: cover;
    }

    &:hover,
    &:focus {
        background-color: var(--color-gray-light3);
    }

    &.-active {
        background-color: var(--color-green);
        border: .1rem solid var(--color-green);
        color: var(--color-white);
    }

    .product__image-promo {
        inset-inline: auto 0;
        font-size: 1.2rem;
        padding: .2rem .5rem;
    }
}
