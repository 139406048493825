@use '../helpers/mixins';

.form__entry {
    position: relative;
    margin-bottom: 1em;

    &.-max-width-30 {
        max-width: 30rem;
    }

    .is-disabled {
        &,
        .form__field,
        .form__label {
            cursor: default;
            color: var(--color-black);
            @include mixins.input-placeholder {
                color: var(--color-gray);
            }
        }

        .form__label--checkbox,
        .form__label--radio {
            &:before,
            &:after {
                background: var(--color-gray);
            }
        }
    }
}
