a {
    color: var(--color-primary);
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }

    &.-arrow {
        &:after {
            content: '';
            display: inline-block;
            background: url(../../../images/green-arrow.svg) no-repeat;
            background-size: cover;
            width: 7px;
            height: 12px;
            margin-left: 10px;
        }
    }
}

a[href^=mailto] {
}

a[rel=external] {
}

a[href^=tel] {
    text-decoration: inherit;
    color: inherit;
    cursor: inherit;
    &:hover, &:focus {
        text-decoration: inherit;
        color: inherit;
        cursor: inherit;
    }
}

button.link {
    border: 0;
    color: var(--color-primary);
    text-decoration: none;
    background: none;
    padding: 0;
    display: inline;
    cursor: pointer;
    margin-bottom: .5em;

    &:hover, &:active, &:focus {
        text-decoration: underline;
        outline: none;
    }
}
