@use '../helpers/mixins';

%wrapper {
    overflow: hidden;
}

.wrapper--gray {
    @extend %wrapper;
    background-color: var(--color-gray-lighter);
}

.wrapper--flex {
    display: flex;
    flex-direction: column;
}

.wrapper--columns {
    display: flex;
    margin-top: 2rem;

    @include mixins.screen-size(mini, small) {
        display: block;
    }

    > * {
        @include mixins.screen-size(medium, large) {
            width: calc(50% - 1.5rem);
            margin-right: 3rem;
        }

        width: 100%;
        margin-bottom: 2rem;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}
