@use '../helpers/mixins';
@use '../variables';

.h-text-align-right {
    text-align: right;
}

@each $screen-size, $screen-size-config in variables.$screen-sizes {
    @include mixins.screen-size($screen-size) {
        .h-v-#{$screen-size}__text-align-right {
            text-align: right;
        }
    }
}

.h-text-align-left {
    text-align: left;
}

.h-text-align-center {
    text-align: center;
}

.h-text-smaller {
    font-size: 1.4rem;
}
