/* ==========================================================================
   Variables
   ========================================================================== */

/* Colors */
//noinspection Stylelint
:root {
    --color-white:                           white;
    --color-black:                           #2d2d2d;
    --color-red:                             red;
    --color-green:                           #2ca536;
    --color-green2:                          #dcf0de;
    --color-gray-lighter:                    #f6f6f6;
    --color-gray-light:                      #efefef;
    --color-gray-light2:                     #e6e7e8;
    --color-gray-light3:                     #c9c9c9;
    --color-gray:                            #979797;
    --color-gray2:                           #646363;
    --color-gray3:                           #444444;
    --color-gray-dark:                       #3e3e3e;
    --color-yellow:                          #FFF5DF;
    --color-orange:                          #E8A200;
    --color-brown:                           #9A8000;

    --color-primary:                         var(--color-green);
    --color-secondary:                       var(--color-gray);
    --default-text-color:                    var(--color-gray2);

    --default-block-margin:                  3rem;
    --default-block-padding:                 min(4vw, 3rem);
}

/* Screen adaptive config */
$breakpoint-medium: 1080px;
$breakpoint-small:  820px;
$breakpoint-mini:   500px;

$tweakpoint-title-bar: 1350px;
$tweakpoint-title-bar--homepage: 850px;
$tweakpoint-button-arrow: 378px;

$screen-sizes: (
    large: (
        typography: (
            font-size:                      1.6rem, // If you change this, also change it in the gulp config for rem_to_px
            line-height:                    1.5,
            margin:                         0 0 1em 0,
            max-width:                      700px
        ),
        grid: (
            screen-min-width:               $breakpoint-medium + 1,
            container-max-width:            1300px,
            total-columns:                  12,
            gutter-width:                   65px,
            side-padding:                   50px,
            subgrids: (
                8: (4)
            )
        )
    ),
    medium: (
        typography: (
            font-size:                      1.6rem, // If you change this, also change it in the grunt config for rem_to_px
            line-height:                    1.5,
            margin:                         0 0 1em 0,
            max-width:                      800px
        ),
        grid: (
            screen-min-width:               $breakpoint-small + 1,
            screen-max-width:               $breakpoint-medium,
            total-columns:                  12,
            gutter-width:                   40px,
            side-padding:                   25px,
        )
    ),
    small: (
        typography: (
            font-size:                      1.6rem, // If you change this, also change it in the grunt config for rem_to_px
            line-height:                    1.5,
            margin:                         0 0 1em 0,
            max-width:                      100%
        ),
        grid: (
            screen-min-width:               $breakpoint-mini + 1,
            screen-max-width:               $breakpoint-small,
            total-columns:                  6,
            gutter-width:                   35px,
            side-padding:                   20px,
            subgrids: (
                2: (1)
            )
        )
    ),
    mini: (
        typography: (
            font-size:                      1.4rem, // If you change this, also change it in the grunt config for rem_to_px
            line-height:                    1.5,
            margin:                         0 0 1.5rem 0,
            max-width:                      100%
        ),
        grid: (
            screen-max-width:               $breakpoint-mini,
            total-columns:                  2,
            gutter-width:                   20px,
            side-padding:                   20px
        )
    )
);
