.location {
    padding-left: 2.3rem;
    position: relative;
    color: var(--color-gray);

    &::before {
        content: '';
        display: inline-block;
        width: 1.4rem;
        height: 1.7rem;
        background-image: url('../../images/location-marker.svg');
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        top: .2rem;
    }
}
