@use '../helpers/mixins';

.products {
    border-spacing: 0 1em;
    width: 100%;
}

.product {
    width: 100%;

    &__image {
        position: relative;
        width: 19rem;
        padding: 0;
        text-align: center;

        a {
            display: block;
        }

        img {
            margin-block-start: 0;
        }

        .-bestseller & {
            border: 2px solid var(--color-black);
            border-right: 0;
        }
    }

    &__image-promo {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;

        padding: .5rem .8rem;

        font-family: var(--font-secondary);
        font-size: 1.4rem;
        font-weight: var(--font-weight-bold);
        color: white;
        text-transform: uppercase;

        background-color: var(--color-red);

        &.-bestseller {
            background-color: var(--color-black);
        }
    }

    &__details {
        padding: 1em 1.5em;
        background-color: var(--color-gray-light);

        .-bestseller & {
            border: 2px solid var(--color-black);
            border-left: 0;
        }
    }

    &__title {
        @include mixins.font-smoothing(off);

        display: block;

        margin-block-end: .5em;

        font-size: 1.8rem;
        font-weight: bold;
        color: var(--color-black);

        border-block-end: 1px solid var(--color-gray-light3);

        > strong {
            margin-inline-start: 1rem;
            font-size: 110%;
            text-transform: uppercase;
        }
    }

    &__specs {
        width: 100%;
        font-size: 1.4rem;

        th {
            color: var(--color-gray2);
            text-align: start;

            &:last-child {
                width: 22em;
                text-align: end;
            }

            .button {
                margin-block: .25em 0;
            }
        }
    }

    &__price-specs {
        padding-inline-end: 2rem;
        text-align: end;
    }

    &__price {
        font-family: var(--font-secondary);
        font-size: 2.6rem;
        font-weight: var(--font-weight-bold);
        color: black;
        text-decoration: none;

        &.-promo {
            color: var(--color-red);
        }
    }

    &__old-price {
        margin-inline-end: .7rem;

        font-family: var(--font-secondary);
        font-size: 1.6rem;
        color: var(--color-gray);
        text-decoration: line-through;
    }

    &__price-term {
        font-family: var(--font-secondary);
        font-size: 1.2rem;
        font-weight: var(--font-weight-medium);
        text-transform: uppercase;

        &.-promo {
            color: var(--color-red);
        }
    }

    &__price-vat-message {
        display: block;

        margin-block-start: -.5rem;

        font-family: var(--font-secondary);
        font-size: 1.1rem;
        color: var(--color-gray);
    }
}
