@use '../helpers/mixins';

.search-bar__container {
    width: 100%;

    .title-bar--homepage + & {

        @include mixins.screen-size(mini, small, medium) {
            margin: 0 auto 3rem;
        }

        margin: 0 auto 6rem;
    }

    .grid__container {
        @include mixins.screen-size(mini, small, medium) {
            padding: 0;
        }
    }
}

.search-bar__form {

    @include mixins.screen-size(mini, small, medium) {
        flex-direction: column;
        padding: 1.8rem 0 3.2rem;
    }

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    &::after {

        @include mixins.screen-size(large) {
            width: 200%;
        }

        content: '';

        position: absolute;
        z-index: -1;
        inset-block-start: 0;
        inset-inline-start: 0;

        width: 100%;
        height: 100%;

        background-color: var(--color-black);
    }

    > :nth-child(n) {

        @include mixins.screen-size(mini, small, medium) {
            padding: 0 1.8rem 2rem;
            border-inline-end: none;
        }

        display: inline-block;
        padding: 0 1.4rem;
        border-inline-end: .1rem solid var(--color-gray2);
    }

    > :last-child {

        @include mixins.screen-size(mini, small, medium) {
            padding: 0 1.8rem;
        }

        padding-inline-end: 0;
        border-inline-end: none;
    }

    .button {
        margin: .5rem 0 0;
    }

    .form__entry {

        @include mixins.screen-size(mini, small, medium) {
            width: 100%;
            padding-inline-end: 1.8rem;
        }

        margin: 0;

        &:last-of-type {
            padding-inline-end: 2.8rem;
        }

        @media only screen and (min-width: 1350px) {
            padding-inline-end: 6rem;
        }
    }

    .form__label {

        @include mixins.screen-size(mini, small, medium) {
            font-size: 1.2rem;
        }

        font-family: var(--font-secondary);
        font-size: 1.3rem;
        font-weight: var(--font-weight-bold);
        color: var(--color-white);
        text-transform: uppercase;
        letter-spacing: .03rem;
    }

    .form__select {

        @include mixins.screen-size(mini, small, medium) {
            padding: 1.2rem 1.3rem 1.3rem;
            background-color: var(--color-gray3);
        }

        padding: .3rem 3rem .4rem 0;

        font-size: 1.6rem;
        color: var(--color-white);

        background-color: transparent;
        background-image: url('../../images/select-arrow-white.svg');
        border: none;
        border-radius: 0;

        appearance: none;

        > option {
            color: var(--color-gray2);
            background-color: var(--color-white);
        }
    }

    .form__field--search-date {
        @include mixins.screen-size(mini, small, medium) {
            padding: 1.2rem 1.3rem 1.3rem;
            background-color: var(--color-gray3);

            &:focus {
                background-color: var(--color-gray3);
            }
        }
    }

    @media only screen and (min-width: 1081px) {
        padding-inline-start: 5rem;
    }

    @media only screen and (min-width: 1350px) {
        padding-inline-start: 2.5rem;
    }
}
