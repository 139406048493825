.booking-steps {
    --step-circle-width: 12px;
    --setp-stripe-width: 23.2px;

    background-color: var(--color-gray-lighter);
    background-image: linear-gradient(90deg, var(--color-primary) 0%, var(--color-primary) var(--step-width), var(--step-width), transparent 100%);
    height: 12px;
    margin: 3em auto 3.8rem;
    mask-image: url('../../images/steps-meter.svg');
    text-indent: -999px;
    width: 223px;

    &.-step-1 {
        --step-width: var(--step-circle-width);
    }

    &.-step-2 {
        --step-width: calc(2 * var(--step-circle-width) + var(--setp-stripe-width));
    }

    &.-step-3 {
        --step-width: calc(3 * var(--step-circle-width) + 2 * var(--setp-stripe-width));
    }

    &.-step-4 {
        --step-width: calc(4 * var(--step-circle-width) + 3 * var(--setp-stripe-width));
    }

    &.-step-5 {
        --step-width: calc(5 * var(--step-circle-width) + 4 * var(--setp-stripe-width));
    }

    &.-step-6 {
        --step-width: calc(6 * var(--step-circle-width) + 5 * var(--setp-stripe-width));
    }

    &.-step-7 {
        --step-width: calc(7 * var(--step-circle-width) + 6 * var(--setp-stripe-width));
    }
}
