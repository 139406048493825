@use '../helpers/mixins';

.step-layout {
    display: grid;
    gap: 2rem;
    grid-template-columns: auto 370px;
    max-width: 1105px;
    margin: 0 auto 4em;

    @include mixins.screen-size(mini, small) {
        grid-template-columns: 1fr;
    }

    &__price-summary {
        position: sticky;
        top: 0;
    }
}
