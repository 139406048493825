@use '../helpers/mixins';
@use '../variables';

/* Button */
%button {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    padding: .5em 1.5em;
    min-height: 3.5em;
    vertical-align: middle;
    margin: 0 1em 1rem 0;

    border: 2px solid transparent;
    border-radius: 2em;
    color: var(--color-white);
    background: var(--color-primary);

    font-family: var(--font-secondary);
    font-size: 1.5rem;
    line-height: 1.2;
    text-decoration: none;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    text-align: center;

    cursor: pointer;
    transition: all .2s ease;

    &:hover, &:focus {
        outline: none;
        text-decoration: none;
    }

    &.-full-width {
        width: 100%;
    }

    &.is-loading[disabled] {
        position: relative;
        color: transparent;

        &:hover, &:focus {
            color: transparent;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin: -20px -20px;
            background-color: var(--color-white);
            border-radius: 100%;
            animation: scaleout 1.0s infinite ease-in-out;
        }
    }

    &.-arrow {
        padding-inline-end: 1em;

        &:after {
            content: '';
            display: inline-block;
            background: url('../../images/white-arrow.svg') no-repeat;
            background-size: cover;
            width: 7px;
            height: 12px;
            margin-left: 15px;
            position: relative;
            top: 1px;

            @media only screen and (max-width: variables.$tweakpoint-button-arrow) {
                display: none;
            }
        }
    }
}

.button {
    @extend %button;

    &:hover, &:focus {
        opacity: .9;
    }

    &:active {
        opacity: .95;
    }
}

.button--transparent {
    @extend %button;

    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.3);

    &:hover, &:focus {
        border-color: rgba(255, 255, 255, 0.4);
    }

    &:active {
        border-color: rgba(255, 255, 255, 0.5);
    }
}

.button--black {
    @extend %button;

    background-color: var(--color-black);

    &:hover, &:focus {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.button--white {
    @extend %button;

    background-color: white;
    color: var(--color-gray-dark);
    border-color: var(--color-gray-light2);
    font-weight: normal;
    @include mixins.font-smoothing(off);

    &:hover, &:focus {
        border-color: var(--color-gray-light3);
    }

    &:active {
        border-color: var(--color-black);
    }
}

@keyframes scaleout {
    0% {
        transform: scale(0.0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}
