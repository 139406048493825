.form__input-button {
    display: flex;
    align-items: flex-start;

    > .form__field--text {
        border-radius: 4px 0 0 4px;
        margin-right: -1px;
    }

    > .button--black {
        margin-right: 0;
        border-radius: 0 .4rem .4rem 0;
        padding: 1.16rem;
    }
}
