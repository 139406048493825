@use '../helpers/mixins';

.breadcrumbs {
    text-align: center;
    margin-bottom: 1.7rem;
    padding: 0;
}

.breadcrumb {
    display: inline-block;
    color: var(--color-gray);

    @include mixins.screen-size(mini, small) {
        margin-bottom: .5rem;
    }

    &::before {
        content: '';
        display: inline-block;
        background-image: url("../../images/gray-arrow.svg");
        background-size: .8rem 1.2rem;
        width: .8rem;
        height: 1.2rem;
        margin: 0 .8rem;
    }

    &.-active {
        color: var(--color-black);
    }

    &.-finished {
        color: var(--color-green);
    }

    &:first-of-type {
        &::before {
            display: none;
        }
    }
}
