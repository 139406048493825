@use '../variables';

.virtual-tour-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 23.7rem;
    position: relative;
    width: 100%;

    &:hover, &:focus, &:active {
        > img {
            opacity: .9;
        }
    }

    > .button {
        margin: 0;
    }

    > svg {
        margin-bottom: 2em;
    }

    > img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        transition: opacity .2s ease-in-out;
        margin-top: 0;
    }

    @media only screen and (min-width: variables.$breakpoint-medium) {
        height: 28.4rem;
        width: 41.3rem;
    }
}
