@use '../helpers/mixins';
@use '../variables';

.masthead {
    overflow-x: hidden;

    &__inner {
        position: relative;
        padding-top: 6rem;
        min-height: 21rem;
        z-index: 5;
    }

    &__logo {
        position: absolute;
        top: 25px;
        left: 0;
        width: 180px;
        height: 180px;
        transition: transform 0.2s ease-in-out;

        @include mixins.screen-size(large) {
            &:hover {
                transform: scale(1.01) rotate(15deg);
            }
        }

        @include mixins.screen-size(mini, small, medium) {
            width: 10rem;
        }
    }


    @include mixins.screen-size(large) {
        &__top-nav {
            position: absolute;
            right: 0;
            top: 0;

            &:before, &:after {
                content: '';
                position: absolute;
                transition: all 0.2s ease-in-out;
            }

            &:before {
                transform: rotate(60deg);
                width: 96px;
                height: 100%;
                border-bottom: 2px solid var(--color-gray-light2);
                bottom: 27px;
                left: -47px;
                z-index: -1;
            }

            &:after {
                height: 2px;
                background-color: var(--color-gray-light2);
                width: 9999px;
                left: 0;
                bottom: 0;
            }

            &:hover {
                &:before {
                    border-bottom-color: var(--color-gray-light3);
                }
                &:after {
                    background-color: var(--color-gray-light3);
                }
            }

            a {
                display: inline-block;
                color: var(--color-gray2);
                font-weight: var(--font-weight-regular);
                margin-left: 2rem;
                padding: 16px 0;

                &:first-child {
                    margin-left: 1rem;
                }
            }
        }

        &__main-nav {
            text-align: right;
            margin: 38px 0;
            padding-left: 180px; // don't go behind the logo

            @media only screen and (max-width: variables.$tweakpoint-title-bar) {
                padding-left: 100px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            li {
                display: inline-block;
            }

            a {
                font-family: var(--font-secondary);
                font-weight: var(--font-weight-bold);
                display: inline-block;
                text-transform: uppercase;
                margin-left: 2rem;

                &.-is-active {
                    color: var(--color-gray2);
                }
            }
        }
    }

    @include mixins.screen-size(mini, small, medium) {
        &__inner {
            min-height: 13.5rem;
        }

        &__logo {
            left: 20px;
            top: 10px;
        }

        &__menu-toggle {
            position: absolute;
            top: 25px;
            right: 30px;
            background-color: var(--color-gray-light2);
            font-family: var(--font-secondary);
            font-size: 1.6rem;
            text-transform: uppercase;
            padding: 0.5em 2.5em 0.5em 1em;
            font-weight: var(--font-weight-medium);
            border: 0;
            cursor: pointer;
            outline: none;

            &:after {
                content: "";
                position: absolute;
                top: 0.9em;
                right: 1em;
                width: 1em;
                height: 0.125em;
                border-top: 0.375em double #000;
                border-bottom: 0.125em solid #000;
            }
        }

        &__menu {
            display: none;
        }

        &__top-nav, &__main-nav {
            a {
                @include mixins.font-smoothing(on);
                font-family: var(--font-secondary);
                font-size: 1.4rem;
                font-weight: var(--font-weight-bold);
                display: block;
                background-color: var(--color-black);
                color: white;
                text-align: center;
                padding: 1em 2em;
                border-top: 1px solid black;
                text-transform: uppercase;

                &.-is-active {
                    color: var(--color-primary);
                }
            }
        }

        &__top-nav {
            margin-top: 2.5rem;
        }

        &__main-nav ul {
            padding: 0;
        }
    }
}
